.filter_header {
    color: rgb(4, 17, 29);
    font-size: 16px;
    font-weight: 600;
}
.rs-btn-subtle{
    color: rgb(4, 17, 29);
    font-size: 16px;
    font-weight: 600;
}
.filter_header_border_top {
    border-top: 1px solid rgb(229, 232, 235);
}
.filter_options_background{
    padding: 10px;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-flow: wrap;
    color: rgb(53, 56, 64);
    background-color: rgb(251, 253, 255);
}
.filter_options_button{
    width: 100%;
}
.rs-dropdown-item-content{
    padding:10px!important;
}
.filter_price_inputgroup{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    height: 35px;
}
.filter_options_checkbox {
    width: 100%;
}
@media screen and (max-device-width: 480px) {
    /* mobile*/
    .filter_mobile_bar{
        top: 88vh;
        left: 10vw;
        position: fixed;
        z-index: 4;
    }
    .filter_mobile_button{
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(32, 129, 226);
        border-radius: 25px;
        box-shadow: rgb(0 0 0 / 25%) 0px 1px 20px;
        color: white;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        -webkit-box-pack: center;
        justify-content: center;
        width: 292px;
        cursor: pointer;
    }
    .filter_mobile_drawer{

    }
    .rs-list-item-content{
        width: 100%;
    }
    .filter_mobile_header_text{
        color: rgb(32, 129, 226);
        font-size: 16px;
        font-weight: 500;
        margin-right: 5px;
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        order: 1;
        -webkit-box-flex: 0;
    }
    .cropper-bar {
        font-size: 24px;
        height: 40px;
        margin: 5px;
        text-align: center;
    }
    .cropper-bar-button {
        height: 30px;
        width: 40px;
        border-radius: 50%;
        background-color: #d9d9d9;
        padding: 6px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .recropper {
        font-size: 24px;
        position: absolute;
        top: 60px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #d9d9d9;
        padding: 6px;
        cursor: pointer;
    }
    ul.pagination li {
        min-width: 20px;
        width: auto;
        /*border: 1px solid #e2e2e2;*/
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin: 1px
    }

    ul.pagination li a {
        text-decoration: none;
        color: #337ab7;
        font-size: 20px;
    }
    .rs-tag-group {
        display: flex;
        flex-wrap: wrap;
    }
    .rs-tag-group > .rs-tag {
        margin: 2px;
        align-items: center;
        height: 40px;
        display: flex;
        border-width: 1px;
        border-style: solid;
        background-color: rgba(21, 178, 229, 0.06);
        border-color: rgb(21, 178, 229);
        color: black;
        padding-left: 20px;
        padding-right: 30px;
        white-space: nowrap;
    }
    .rs-tag-icon-close {
        position: absolute;
        top: 10px;
        right: 8px;
        font-style: normal;
        font-family: 'rsuite-icon-font';
        cursor: pointer;
        font-size: 12px;
    }
    .rs-grid-container-fluid {
        margin-bottom: 10px;
    }
}

@media screen and (min-device-width: 481px) {
    /*PC*/
    .recropper {
        font-size: 24px;
        position: absolute;
        top: 60px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #d9d9d9;
        padding: 6px;
        cursor: pointer;
    }
    .popup-upload-image{
        width: 100%;
        height: 100%;
    }
    .cropper-bar {
        font-size: 24px;
        height: 40px;
        margin: 5px;
        text-align: center;
    }
    .cropper-bar-button {
        height: 30px;
        width: 40px;
        border-radius: 50%;
        background-color: #d9d9d9;
        padding: 6px;
        margin-left: 20px;
        margin-right: 20px;
    }
    ul.pagination li {
        min-width: 50px;
        width: auto;
        /*border: 1px solid #e2e2e2;*/
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin: 5px
    }

    ul.pagination li a {
        text-decoration: none;
        color: #337ab7;
        font-size: 20px;
    }
    .rs-tag-group {
        display: flex;
        flex-wrap: wrap;
    }
    .rs-tag-group > .rs-tag {
        margin: 10px;
        align-items: center;
        height: 50px;
        display: flex;
        border-width: 1px;
        border-style: solid;
        background-color: rgba(21, 178, 229, 0.06);
        border-color: rgb(21, 178, 229);
        color: black;
        padding-left: 20px;
        padding-right: 30px;
        white-space: nowrap;
    }
    .rs-tag-icon-close {
        position: absolute;
        top: 15px;
        right: 8px;
        font-style: normal;
        font-family: 'rsuite-icon-font';
        cursor: pointer;
        font-size: 12px;
    }
    .rs-grid-container-fluid {
        margin-bottom: 10px;
    }
    .css-cxmlok {
        padding-top: 100px!important;
    }
}


.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: black;
}

.pagination {
    display: flex;
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
}



ul.pagination li.active a {
    color: white;
}
ul.pagination li.active {
    background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: blue;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.rs-container{
    max-width: 100%;
}
.rs-check-tree{
 overflow-x: hidden;
}
