.token-border{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235) !important;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.token-border:hover {
    box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
    transform: translate(0,-5px);
    transition-delay: 0s !important;
}
.token-image{
    object-fit: contain;
    width: auto;
    border-radius: 4px;
    /*height: 232px;*/
    max-width: 100%;
    max-height: 100%;
}
@media screen and (max-device-width: 480px) {
    /* mobile*/
    .token_avator{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        max-width: 100%;
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .token_avator_name{
        margin-left: 1px;
        max-width: 50px;
        overflow: hidden;
        line-height: 24px;
        color: rgb(53, 56, 64);
        font-size: 12px;
        letter-spacing: 0.1px;
        font-weight: 600;

    }
    .verifyIcon{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: flex;
        width: 28px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-flow: column;
        flex-flow: column;
        float: right;
    }
    .token_card_title{
        height: 50px;
        overflow: hidden;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(53, 56, 64);
        font-size: 12px;
        letter-spacing: 0.1px;
        font-weight: 600;
    }
    .token_card_collection{
        overflow: hidden;
        line-height: 24px;
        letter-spacing: 0.1px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(112, 122, 131);
        font-weight: 500;
        font-size: 12px;
    }
}

@media screen and (min-device-width: 481px) {
    /*PC*/
    .token_avator{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        max-width: 100%;
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }
    .token_avator_name{
        margin-left: 10px;
        line-height: 24px;
        color: rgb(53, 56, 64);
        font-size: 12px;
        letter-spacing: 0.1px;
        font-weight: 600;
    }
    .verifyIcon{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: flex;
        width: 50px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-flow: column;
        flex-flow: column;
        float: right;
    }
    .token_card_title{
        height: 50px;
        overflow: hidden;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;

        color: rgb(53, 56, 64);
        font-size: 12px;
        letter-spacing: 0.1px;
        font-weight: 600;
    }
    .token_card_collection{
        overflow: hidden;
        line-height: 24px;
        letter-spacing: 0.1px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(112, 122, 131);
        font-weight: 500;
        font-size: 12px;
    }
}
