.menu-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
    cursor: pointer;
}






@media screen and (max-device-width: 480px) {
    /* mobile*/
    .headerDivOfficial{
        box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
        position: fixed;
        /*height: 100px;*/
        z-index: 100;
        background-color: white;
        width: 100%;
    }
    .textSearchPopupItemsText {
        font-weight: 600;
        font-size: 14px;
        color: rgb(4, 17, 29);
        height: 80px;
        display: flex;
        align-items: center;
    }
    .connect_wallet_mobile_bar{
        bottom: 20px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        z-index: 4;
        margin-bottom:50px;
        margin-left: 40px;
    }
    .connect_wallet_mobile_button{
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(32, 129, 226);
        border-radius: 25px;
        box-shadow: rgb(0 0 0 / 25%) 0px 1px 20px;
        color: white;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        -webkit-box-pack: center;
        justify-content: center;
        width: 292px;
        cursor: pointer;
    }

    .background-fullscreen {
        position: fixed;
        left: 0px;
        top: 68px;
        right: 0px;
        bottom: 0px;
        overflow: hidden;
        /*height: 700px;*/
        background-image: -webkit-linear-gradient(
                88deg, rgba(26, 48, 18, .82), rgba(0, 0, 0, .89));
    }
    .w-background-video {
        z-index: -1;
        position: relative;
    }
    .w-background-video > video {
        background-size: cover;
        background-position: 50% 50%;
         position: fixed;
        right: -100%;
        bottom: -100%;
        top: -100%;
        left: -100%;
        margin: auto;
        min-width: 100%;
         min-height: 1000px;
        /*height: 700px;*/
        z-index: -100;
    }
    #mc_embed_signup {
     background: unset;
   }
    .searchBar_outlook{
        margin-top: 90px;
    }
}

@media screen and (min-device-width: 481px) {
    /*PC*/
    .headerDivOfficial{
        box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
        position: sticky;
        height: 100px;
    }
    .background-fullscreen {
        /*position: fixed;*/
        /*left: 0px;*/
        /*top: 80px;*/
        /*right: 0px;*/
        /*bottom: 0px;*/
        overflow: hidden;
        height: 700px;
        background-image: -webkit-linear-gradient(
                88deg, rgba(26, 48, 18, .82), rgba(0, 0, 0, .89));
    }
    .w-background-video {
        z-index: -1;
        position: relative;
    }
    .w-background-video > video {
        background-size: cover;
        background-position: 50% 50%;
        /* position: relative; */
        right: -100%;
        bottom: -100%;
        top: -100%;
        left: -100%;
        margin: auto;
        min-width: 100%;
        /* min-height: 700px; */
        height: 700px;
        z-index: -100;
    }
}




