.iiz__hint {
    display: none;
}

.token_field_label {
    font-family: inherit;
    font-weight: 900;
    color: rgb(128, 128, 128);
    font-size: 13px;
    vertical-align: inherit;
}

.field_bold_label{
    font-family: inherit;
    font-weight: 900;
    color: rgb(4, 4, 5);
    vertical-align: inherit;
}
