.headerDiv{
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    position: sticky;
}


@media screen and (max-device-width: 480px) {
    /* mobile*/
    .headerDiv {
        box-shadow: 0 0 8px 0 rgb(4 17 29 / 25%);
        position: sticky;
        z-index: 100;
        background-color: #fff;
        width: 100vw;
    }
    .textSearchPopupItemsText {
        font-weight: 600;
        font-size: 14px;
        color: rgb(4, 17, 29);
        height: 80px;
        display: flex;
        align-items: center;
    }
    .connect_wallet_mobile_bar{
        bottom: 20px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        z-index: 4;
        margin-bottom:50px;
        margin-left: 40px;
    }
    .connect_wallet_mobile_button{
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(32, 129, 226);
        border-radius: 25px;
        box-shadow: rgb(0 0 0 / 25%) 0px 1px 20px;
        color: white;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        -webkit-box-pack: center;
        justify-content: center;
        width: 292px;
        cursor: pointer;
    }
}

@media screen and (min-device-width: 481px) {
    /*PC*/
    .headerDiv {
        box-shadow: 0 0 8px 0 rgb(4 17 29 / 25%);
        position: absolute;
        z-index: 100;
        background-color: #fff;
        width: 100vw;
    }
}




