/*.headerDiv{*/
/*    z-index:999*/
/*}*/

/*@media screen and (max-device-width: 480px) {*/
/*!* mobile*!*/
/*    .whole-page-section{*/
/*        margin-left:32px;*/
/*        margin-right:32px;*/
/*        position:relative;*/
/*        top:100px;*/
/*        height: 550px;*/
/*    }*/

/*    .header-text-border{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        !*margin-top: 25%;*!*/
/*    }*/
/*    .header-text{*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: 600;*/
/*        font-size: 48px;*/
/*        line-height: 60px;*/
/*        text-align: center;*/

/*        color: #FFFFFF;*/

/*    }*/
/*    .homepage-footer{*/
/*        !*top: 800px;*!*/
/*        position: absolute;*/
/*        width: 100%;*/
/*        !*height: 300px;*!*/
/*    }*/
/*    .homepage-footer-join-community{*/
/*        height: 40px;*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        !*font-weight: 500;*!*/
/*        font-size: 20px;*/
/*        width: 80%;*/
/*    }*/
/*    .homepage-footer-join-community-iconlist{*/
/*        display: flex;*/
/*        justify-content: left;*/
/*        margin-left: -12px;*/
/*    }*/
/*    .homepage-footer-join-community-icon-single{*/
/*        margin-left: 12px;*/
/*        width: 32px;*/
/*        height:32px;*/
/*        cursor: pointer;*/
/*    }*/
/*    .email{*/
/*        width: 180px;*/
/*        height: 40px;*/
/*        left: 712px;*/
/*        top: 223px;*/

/*        background: #FFFFFF;*/
/*        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);*/
/*        border-radius: 5px;*/
/*        border-color: #FFFFFF;*/
/*    }*/
/*    .signUpButton{*/
/*        width: 70px;*/
/*        height: 40px;*/
/*        left: 1152px;*/
/*        top: 223px;*/
/*        background: #5977BA;*/
/*        border-radius: 5px;*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: bold;*/
/*        font-size: 15px;*/
/*        line-height: 159%;*/
/*        text-align: center;*/
/*        color: #FFFFFF;*/

/*    }*/
/*    #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}*/
/*    .header-text-border-about{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border: 1px solid white;*/
/*        border-radius: 10px;*/
/*        width: 80%;*/
/*        left: 10%;*/
/*        top: 100px;*/
/*        position: fixed;*/
/*        padding: 5px;*/
/*    }*/

/*    .header-text-about{*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: 500;*/
/*        font-size: 18px;*/
/*        line-height: 159%;*/
/*        color: #FFFFFF;*/
/*    }*/
/*    .header-text-border-features{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border-radius: 10px;*/
/*        width: 80%;*/
/*        left: 10%;*/
/*        top: 100px;*/
/*        position: relative;*/
/*        padding: 5px;*/
/*    }*/
/*    .header-text-border-features-subSections{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border: 1px solid white;*/
/*        border-radius: 10px;*/

/*        !*left: 10%;*!*/
/*        padding: 5px;*/
/*        margin-top: 20px;*/
/*    }*/

/*}*/
/*@media screen and (min-device-width: 481px) {*/
/*    !*PC*!*/
/*    .whole-page-section{*/
/*        margin-left:32px;*/
/*        margin-right:32px;*/
/*        position:relative;*/
/*        top:-400px;*/
/*    }*/

/*    .header-text-border{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        !*margin-top: 25%;*!*/
/*    }*/
/*    .header-text{*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: 600;*/
/*        font-size: 62.429px;*/
/*        line-height: 94px;*/
/*        text-align: center;*/

/*        color: #FFFFFF;*/

/*    }*/
/*    .homepage-footer{*/
/*        top: 800px;*/
/*        position: absolute;*/
/*        width: 100%;*/
/*        !*height: 300px;*!*/
/*    }*/
/*    .homepage-footer-join-community{*/
/*        height: 66px;*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: 500;*/
/*        font-size: 36px;*/
/*    }*/
/*    .homepage-footer-join-community-iconlist{*/
/*        display: flex;*/
/*        justify-content: left;*/
/*    }*/
/*    .homepage-footer-join-community-icon-single{*/
/*        margin-left: 12px;*/
/*        width: 32px;*/
/*        height:32px;*/
/*        cursor: pointer;*/

/*    }*/
/*    .email{*/
/*        width: 300px;*/
/*        height: 40px;*/
/*        left: 712px;*/
/*        top: 223px;*/

/*        background: #FFFFFF;*/
/*        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);*/
/*        border-radius: 5px;*/
/*        border-color: #FFFFFF;*/
/*    }*/
/*    .signUpButton{*/
/*        width: 128px;*/
/*        height: 40px;*/
/*        left: 1152px;*/
/*        top: 223px;*/

/*        background: #5977BA;*/
/*        border-radius: 5px;*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: bold;*/
/*        font-size: 18px;*/
/*        line-height: 159%;*/
/*        !* identical to box height, or 29px *!*/

/*        text-align: center;*/

/*        color: #FFFFFF;*/

/*    }*/
/*    #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}*/
/*    .header-text-border-about{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border: 1px solid white;*/
/*        border-radius: 10px;*/
/*        width: 80%;*/
/*        left: 10%;*/
/*        top: 300px;*/
/*        position: fixed;*/
/*        padding: 50px;*/
/*    }*/

/*    .header-text-about{*/
/*        font-family: Poppins;*/
/*        font-style: normal;*/
/*        font-weight: 500;*/
/*        font-size: 18px;*/
/*        line-height: 159%;*/
/*        color: #FFFFFF;*/
/*    }*/
/*    .header-text-border-features{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border-radius: 10px;*/
/*        width: 80%;*/
/*        left: 10%;*/
/*        top: 200px;*/
/*        position: relative;*/
/*        padding: 50px;*/
/*    }*/
/*    .header-text-border-features-subSections{*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        flex-direction: column;*/
/*        border: 1px solid white;*/
/*        border-radius: 10px;*/

/*        !*left: 10%;*!*/
/*        padding: 50px;*/
/*        margin-top: 50px;*/
/*    }*/

/*}*/


/*.mc-field-group-title{*/
/*    font-size: 18px;*/
/*    color: white;*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*}*/
/*.mc-field-group-input{*/
/*    background-color: gray;*/
/*    border: 1px #FFFFFF solid;*/
/*    box-sizing: border-box;*/
/*    border-radius: 9px;*/
/*    width: 100%;*/
/*    height: 40px;*/
/*    margin-top: 10px;*/
/*    color: white;*/
/*    margin-bottom: 35px;*/
/*}*/
/*.signUpButton{*/
/*    background-color: black!important;*/
/*}*/

