@media screen and (max-device-width: 480px) {
    /* mobile*/

}

@media screen and (min-device-width: 481px) {
    /*PC*/

}
.collectionSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 250px;
}
.avatarDiv {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100px;
    max-width: 100px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    background: rgb(229, 232, 235);
    border: 2px solid rgb(229, 232, 235);
}
.collectionName {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 0px;
    color: rgb(4, 17, 29);
    margin-bottom: 4px;
    margin-top: 0.25rem;
    max-width: 600px;
    text-align: center;
    line-height: 110%;
}
.collectionDesc {
    color: rgb(128, 128, 128);
    text-align: center;
    vertical-align: inherit;
    max-height: 40px;
    overflow: hidden;
}
.collectionContractAddressLabel{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 2px 10px 2px 5px;
    width: 120px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgb(128, 128, 128);
    background: rgba(4, 4, 5, 0.04);
    transition: all 0.12s ease-in-out 0s;
    cursor: pointer;
}
