@media screen and (min-width: 981px) {
    .globalContentDiv {
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (min-width: 768px) {
    .globalContentDiv {
        padding-left: 28px;
        padding-right: 28px;
    }
}
/*@media screen and (min-width: 578px) {*/
/*    .globalContentDiv {*/
/*        padding-left: 24px;*/
/*        padding-right: 24px;*/
/*    }*/
/*}*/
.globalContentDiv {
    padding-left: 24px;
    padding-right: 24px;
}

.slick-prev:before, .slick-next:before {
     color: gray;
}
