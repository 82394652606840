@media screen and (max-device-width: 480px) {
    /* mobile*/

}

@media screen and (min-device-width: 481px) {
    /*PC*/


}
.collectionCard-background{
    height: 90px;
    width: 100%;
    background-color: rgba(4, 4, 5, 0.03);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
