

@media screen and (max-device-width: 480px) {
    /* mobile*/
    .header-left{
       display: none;
    }
    .section-title{
        font: 400 20px/26px 'Google Sans','GoogleSans-Local','Roboto',sans-serif;
        letter-spacing: .2px;
    }
}
@media screen and (min-device-width: 481px) {
    /*PC*/
    .whole-page-section{
        margin-left:32px;
        margin-right:32px;
    }
    .header-left{
        font: 400 34px/44px 'Google Sans','GoogleSans-Local','Roboto',sans-serif;
        letter-spacing: 0;
        margin-top: 10%;
        width: 50%;
        padding-left: 60px;
    }
    .header-right{
        max-width: 50%;
    }
    .section-title{
        font: 400 30px/34px 'Google Sans','GoogleSans-Local','Roboto',sans-serif;
        letter-spacing: .2px;
    }
}
