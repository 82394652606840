.gallerySubtitleLabel {
    font-size: 24px;
    font-family: inherit;
    font-weight: 900;
    vertical-align: inherit;
    margin-bottom: 10px;
}
@media (min-width: 981px){
    .gallerySubtitleLabel {
        font-size: 30px;
    }
}
@media (min-width: 768px){
    .gallerySubtitleLabel {
        font-size: 27px;
    }
}
